import apiUrl from './url';

class PlanInfoService {
  #httpClient;

  constructor(httpClient) {
    this.#httpClient = httpClient;
    this.baseUrl = `${apiUrl}`;
  }

  fetchPlanInfo() {
    const url = `${this.baseUrl}/planInfo`;
    return this.#httpClient.get(url);
  }
}

export default PlanInfoService;
