import React from 'react';
import PropTypes from 'prop-types';
import { useLocale } from '@unisporkal/localization';
import LocalizationPicker from '../LocalizationPicker/LocalizationPicker';
import {
  LearnMoreLinks,
  CompanyLinks,
  SupportLinks,
} from '../FooterContent.model';
import styles from './FullFooter.module.scss';
import LegalBlock from '../LegalBlock/LegalBlock';
import LogoBlock from '../LogoBlock/LogoBlock';
import NavBlock from '../NavBlock/NavBlock';
import SocialBlock from '../SocialBlock/SocialBlock';
import useCountryLanguagePicker from '../../../../hooks/useCountryLanguagePicker';

const FullFooter = ({ teamMember, countryCode }) => {
  const locale = useLocale();
  const languageKey = (locale || 'en-US').slice(0, 2);
  const countryLanguagePicker = useCountryLanguagePicker();
  const { country, language } = countryLanguagePicker.getDefault(
    countryCode,
    languageKey
  );

  return (
    <div
      data-testid="full-layout-footer"
      className={styles.fullFooter}
    >
      <nav className={styles.navLinks}>
        <nav className={styles.navBlockLocale}>
          <LogoBlock />
          <LocalizationPicker
            country={country}
            languageName={language.languageName}
          />
        </nav>
        <NavBlock
          column-type="learn_more"
          content={LearnMoreLinks(teamMember)}
        />
        <NavBlock
          column-type="company"
          content={CompanyLinks(country.code)}
        />
        <NavBlock
          column-type="support"
          content={SupportLinks()}
        />

        <nav className={styles.navBlock}>
          <SocialBlock />
        </nav>
      </nav>
      <LegalBlock
        iloxTag="metadata_footer"
        locale={locale}
      />
    </div>
  );
};

FullFooter.propTypes = {
  countryCode: PropTypes.string.isRequired,
  teamMember: PropTypes.bool.isRequired,
};

export default FullFooter;
