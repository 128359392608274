import React from 'react';
import PropTypes from 'prop-types';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import { ListItem } from '@unisporkal/alliance-ui-list';
import { Link } from '@unisporkal/alliance-ui-link';
import { useTranslations, useLocale } from '@unisporkal/localization';
import { useSiteMap } from '@unisporkal/sites';

const FooterLinks = ({ links }) => {
  const t = useTranslations();
  const siteMap = useSiteMap();
  const locale = useLocale();

  return links.map((link) => {
    const linkHref = link.supportNumber
      ? `tel:${link.supportNumber.replace(/ /g, '')}`
      : siteMap[link.urlFnName](locale);
    const renderContent = () => {
      if (link.supportNumber) {
        return link.supportNumber;
      }
      if (link.textDisplay) {
        return link.textDisplay;
      }

      return t(link.iloxKey);
    };

    const getSelectionKey = () => {
      if (link.supportNumber) {
        return link.supportNumber;
      }
      if (link.textDisplay) {
        return link.textDisplay;
      }

      return link.iloxKey;
    };

    const navigationTracking = () => {
      const ga4Event = navigationEvent({
        link_text: renderContent(),
        link_url: linkHref,
        ui_element_location: 'footer',
        navigation_selection: getSelectionKey(),
      });
      ga4Track(ga4Event);
    };

    if (link.hideLink || linkHref === '') {
      return null;
    }
    return (
      <ListItem key={link.dataNavId}>
        <Link
          data-testid={link.dataTestId}
          href={linkHref}
          data-nav={link.dataNavId}
          onClick={navigationTracking}
          target={link.target || '_self'}
          rel={link.target === '_blank' ? 'noopener noreferrer' : ''}
        >
          {renderContent()}
        </Link>
      </ListItem>
    );
  });
};

FooterLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      dataTestId: PropTypes.string,
      iloxKey: PropTypes.string,
      urlFnName: PropTypes.string,
      dataNavId: PropTypes.string,
    })
  ),
};

export default FooterLinks;
