import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import styles from './MinimalFooter.module.scss';

const MinimalFooter = () => {
  const t = useTranslations();
  const year = new Date().getFullYear();
  return (
    <div
      data-testid="minimal-layout-footer"
      className={styles.minimalFooter}
    >
      <p className={styles.copyright}>
        {`©${year} iStockphoto LP. `}
        <span>{t('istock_trademark_statement')}</span>
      </p>
    </div>
  );
};

export default MinimalFooter;
