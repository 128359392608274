import { useMemo } from 'react';
import { useSite } from '@unisporkal/sites';
import CountryLanguagePicker from '../footer/FooterData/CountryLanguagePicker';

const useCountryLanguagePicker = () => {
  const site = useSite().nickname;
  const countryLanguagePicker = useMemo(
    () => new CountryLanguagePicker(site),
    [site]
  );
  return countryLanguagePicker;
};

export default useCountryLanguagePicker;
