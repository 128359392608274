import React from 'react';
import { useTranslations, useLocale } from '@unisporkal/localization';
import FooterLinks from '../FooterLinks/FooterLinks';
import LegalBlock from '../LegalBlock/LegalBlock';
import { SupportLinks } from '../FooterContent.model';

import styles from './CheckoutFooter.module.scss';

const CheckoutFooter = () => {
  const t = useTranslations();
  const locale = useLocale();
  return (
    <div
      data-testid="checkout-layout-footer"
      className={styles.checkoutFooter}
    >
      <nav>
        <ul className={styles.navLinks}>
          <li className={styles.categoryTitle}>
            {t(SupportLinks().headerILoxKey)}
          </li>
          <FooterLinks links={SupportLinks().links} />
        </ul>
      </nav>
      <LegalBlock
        iloxTag="istock_copyright"
        locale={locale}
      />
    </div>
  );
};

export default CheckoutFooter;
