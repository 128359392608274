import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@unisporkal/localization';
import { List, ListItem } from '@unisporkal/alliance-ui-list';
import FooterLinks from '../FooterLinks/FooterLinks';
import styles from './NavBlock.module.scss';

const NavBlock = ({ content }) => {
  const t = useTranslations();
  return (
    <nav className={styles.navBlock}>
      <List>
        <ListItem className={styles.categoryTitle}>
          {t(content.headerILoxKey)}
        </ListItem>
        <FooterLinks links={content.links} />
      </List>
    </nav>
  );
};

NavBlock.propTypes = {
  content: PropTypes.shape({
    headerILoxKey: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        iloxKey: PropTypes.string,
        urlFnName: PropTypes.string,
        supportNumber: PropTypes.string,
        dataNavId: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default NavBlock;
