import React from 'react';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import { useSiteMap } from '@unisporkal/sites';
import styles from './SocialBlock.module.scss';
import { SocialMediaLinks } from '../FooterContent.model';

const SocialBlock = () => {
  const siteMap = useSiteMap();
  return (
    <nav className={styles.socialLinks}>
      <ul>
        {['Facebook', 'Twitter', 'Instagram'].map((key) => {
          const options = SocialMediaLinks[key];
          const href = siteMap[options.urlFnName]();
          const navigationTracking = () => {
            const ga4Event = navigationEvent({
              link_text: options.title,
              link_url: href,
              ui_element_location: 'footer',
              navigation_selection: key,
            });
            ga4Track(ga4Event);
          };
          return (
            <li key={key}>
              <a
                aria-label={options.title}
                data-testid={options.testId}
                data-nav={options.dataNavId}
                className={styles[options.class]}
                href={href}
                onClick={navigationTracking}
              >
                &nbsp;
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default SocialBlock;
