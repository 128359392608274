import apiUrl from './url';

class BoardsService {
  #httpClient;

  constructor(httpClient) {
    this.#httpClient = httpClient;
    this.baseUrl = `${apiUrl}`;
  }

  getBoardsData() {
    const url = `${this.baseUrl}/boards`;
    return this.#httpClient.get(url);
  }

  createNewBoard(boardName) {
    const url = `${this.baseUrl}/boards`;
    return this.#httpClient.post(url, { name: boardName });
  }
}

export default BoardsService;
