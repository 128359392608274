import apiUrl from './url';

class HeaderDataService {
  #httpClient;

  constructor(httpClient) {
    this.#httpClient = httpClient;
    this.baseUrl = `${apiUrl}`;
  }

  fetchHeaderData() {
    const url = `${this.baseUrl}/header`;
    return this.#httpClient.get(url);
  }

  fetchGettyHeaderData() {
    const url = `${this.baseUrl}/header/getty`;
    return this.#httpClient.get(url);
  }
}

export default HeaderDataService;
