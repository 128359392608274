/* eslint-disable import/prefer-default-export */
export const QUERY_TYPE = Object.freeze({
  FOOTER_DATA: ['FOOTER_DATA'],
  TRENDING_SEARCH_DATA: ['TRENDING_SEARCH_DATA'],
  USER: ['USER'],
  HEADER_DATA: ['HEADER_DATA'],
  PLAN_INFO: ['PLAN_INFO'],
  RECENT_BOARDS: ['RECENT_BOARDS'],
  CART_COUNT: ['CART_COUNT'],
});
