import { useSuspenseQuery } from '@tanstack/react-query';
import { QUERY_TYPE } from '../../actions/types';
import useServiceClient from '../useServiceClient';

const useFooterPropsQuery = () => {
  const { footerDataService } = useServiceClient();

  return useSuspenseQuery({
    queryKey: QUERY_TYPE.FOOTER_DATA,
    queryFn: () => footerDataService.fetchFooterData(),
  });
};

export default useFooterPropsQuery;
