import React from 'react';
import PropTypes from 'prop-types';
import { useSite } from '@unisporkal/sites';
import FullFooter from './FullFooter/FullFooter';
import CheckoutFooter from './CheckoutFooter/CheckoutFooter';
import MinimalFooter from './MinimalFooter/MinimalFooter';
import styles from './Footer.module.scss';
import useFooterPropsQuery from '../../../hooks/queries/useFooterPropsQuery';

const Footer = (props) => {
  const { layout } = props;
  const site = useSite();
  const { tld } = site;
  const privacyLinkTlds = ['com'];
  const showUSPrivacyLink = privacyLinkTlds.includes(tld);
  const {
    data: { countryCode, isTeamMember },
  } = useFooterPropsQuery();

  const footerLayout = () => {
    if (layout === 'minimal') {
      return <MinimalFooter showUSPrivacyLink={showUSPrivacyLink} />;
    }

    if (layout === 'checkout') {
      return <CheckoutFooter />;
    }
    return (
      <FullFooter
        countryCode={countryCode}
        teamMember={isTeamMember}
        showUSPrivacyLink={showUSPrivacyLink}
      />
    );
  };

  return (
    <div
      data-testid="istock-footer"
      className={styles.iStockFooter}
    >
      {footerLayout()}
    </div>
  );
};

Footer.propTypes = {
  layout: PropTypes.string.isRequired,
};

export default Footer;
