import { useMemo } from 'react';
import { useIsomorphicAxios } from '@unisporkal/federation';
import { httpClient } from '../utils/http-client';
import FooterDataService from '../services/footerDataService';
import HeaderDataService from '../services/headerDataService';
import PlanInfoService from '../services/planInfoService';
import BoardsService from '../services/boardsService';
import CartCountService from '../services/cartCountService.ts';

const useServiceClient = () => {
  const axios = useIsomorphicAxios();

  const serviceClient = useMemo(() => {
    const client = httpClient(axios);

    const boardsService = new BoardsService(client);
    const footerDataService = new FooterDataService(client);
    const headerDataService = new HeaderDataService(client);
    const planInfoService = new PlanInfoService(client);
    const cartCountService = new CartCountService(client);

    return {
      boardsService,
      footerDataService,
      headerDataService,
      planInfoService,
      cartCountService,
    };
  }, []);

  return serviceClient;
};

export default useServiceClient;
