import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@unisporkal/localization';
import { useSiteMap } from '@unisporkal/sites';
import useFooterPropsQuery from '../../../../hooks/queries/useFooterPropsQuery';
import styles from './LegalBlock.module.scss';

const LegalBlock = ({ iloxTag, locale }) => {
  const t = useTranslations();
  const year = new Date().getUTCFullYear();
  const siteMap = useSiteMap();
  const {
    data: { geoCountry },
  } = useFooterPropsQuery();

  const isUS = locale === 'en-us' && geoCountry === 'USA';

  return (
    <div
      data-nosnippet
      className={styles.legalBlock}
    >
      <hr />
      <p
        className={styles.footerCopy}
        data-testid="footer-copy"
      >
        {t(iloxTag, { zero: year })}
      </p>
      {isUS ? (
        <a
          className={styles.footerCopy}
          data-testid="privacy-policy"
          href={siteMap.privacyPolicy()}
          target="privacy_policy"
          rel="nofollow"
        >
          {t('do_not_sell_or_share_information')}
        </a>
      ) : null}
    </div>
  );
};

LegalBlock.propTypes = {
  iloxTag: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

export default LegalBlock;
