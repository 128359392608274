import React from 'react';
import { navigationEvent, ga4Track } from '@unisporkal/ga4';
import { useSiteMap } from '@unisporkal/sites';
import styles from './LogoBlock.module.scss';
import IStockGlobal from '../icons/istock_global';

const LogoBlock = () => {
  const siteMap = useSiteMap();
  const href = siteMap.home();
  const ariaLabel = 'iStock Photos homepage';
  const navigationTracking = () => {
    const ga4Event = navigationEvent({
      link_text: ariaLabel,
      link_url: href,
      ui_element_location: 'footer',
      navigation_selection: 'istock-logo',
    });
    ga4Track(ga4Event);
  };

  return (
    <a
      data-testid="istock-logo"
      className={styles.iStockLogo}
      aria-label={ariaLabel}
      href={href}
      onClick={navigationTracking}
    >
      <IStockGlobal />
    </a>
  );
};
export default LogoBlock;
