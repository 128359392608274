// This is a common config setting used for all http requests
export const baseConfig = {
  headers: {
    Accept: 'application/json',
  },
};

/**
 * Only used for http get and post at this time. Implement other http methods as you need them
 * @param http
 * @returns {{post: (function(*, *=, *=): *), get: (function(*, *=): *)}}
 */
export function httpClient(http) {
  return {
    get: (path, config = {}) =>
      http
        .get(path, { ...config, ...baseConfig })
        .then((response) => response.data),
    post: (path, data = {}, config = {}) =>
      http
        .post(path, data, { ...config, ...baseConfig })
        .then((response) => response.data),
  };
}
