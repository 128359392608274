/* eslint-disable no-restricted-syntax */
import istockCountryLanguages from './istock.json';

export default class CountryLanguagePicker {
  #continents;

  #countries;

  constructor() {
    // TODO - return base on nickname once we have getty data
    this.#continents = istockCountryLanguages;
    this.#countries = {};
    this.c = istockCountryLanguages;
  }

  getContinentCountries(continent) {
    const countryCodes = this.#continents[continent];
    return Object.keys(countryCodes).map((key) =>
      this.#populateCountry(key, countryCodes[key])
    );
  }

  getCountry(countryCode) {
    if (this.#countries[countryCode]) return this.#countries[countryCode];
    const rawCountry = this.#getRawCountry(countryCode);
    return this.#populateCountry(countryCode, rawCountry);
  }

  getDefault(countryCode, languageKey) {
    let country = countryCode ? this.getCountry(countryCode) : null;
    let language = country
      ? country.languages.find((lang) => lang.languageKey === languageKey)
      : null;

    // change the country if language was not found in the saved country (ex: when changing url)
    if (!language) {
      country = this.#getCountryForMasterLanguage(languageKey);
      language = country.languages.find(
        (lang) => lang.languageKey === languageKey
      );
    }

    return {
      language,
      country,
    };
  }

  #getRawCountry(code) {
    for (const continentKey of Object.keys(this.#continents)) {
      const continent = this.#continents[continentKey];
      const retCode = Object.keys(continent).find(
        (countryCode) => code === countryCode
      );
      if (retCode) return this.#continents[continentKey][retCode];
    }
    return null;
  }

  #getCountryForMasterLanguage(key) {
    for (const continentKey of Object.keys(this.#continents)) {
      for (const countryKey of Object.keys(this.#continents[continentKey])) {
        const country = this.#continents[continentKey][countryKey];
        for (const langKey of Object.keys(country.languages)) {
          const lang = country.languages[langKey];
          if (key === langKey && lang.masterLanguage) {
            return this.#populateCountry(countryKey, country);
          }
        }
      }
    }
    // fallback to us
    return this.getCountry('us');
  }

  #populateCountry(code, country) {
    if (!country) return null;
    if (!this.#countries[code]) {
      this.#countries[code] = {
        ...country,
        code,
        countryName:
          CountryLanguagePicker.#getDefaultLanguage(country).countryName,
        languages: CountryLanguagePicker.#normalizeLanguages(country.languages),
      };
    }
    return this.#countries[code];
  }

  static #normalizeLanguages(languageObjects) {
    // transforms languages into a propper array
    return Object.keys(languageObjects).map((key) => ({
      ...languageObjects[key],
      languageKey: key,
    }));
  }

  static #getDefaultLanguage(country) {
    const keys = Object.keys(country.languages);
    const key =
      keys.length === 1
        ? keys[0]
        : keys.find((l) => country.languages[l].default === true);
    return country.languages[key];
  }
}
