import apiUrl from './url';

class CartCountService {
  #httpClient;
  baseUrl: string;

  constructor(httpClient) {
    this.#httpClient = httpClient;
    this.baseUrl = `${apiUrl}`;
  }

  getCartCount() {
    const url = `${this.baseUrl}/carts/item-count`;
    return this.#httpClient.get(url);
  }
}

export default CartCountService;
