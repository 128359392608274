/* eslint-disable camelcase */
import React, { useEffect, useState, Suspense } from 'react';
import PropTypes, { shape } from 'prop-types';
import { Button, ButtonVariantEnum } from '@unisporkal/alliance-ui-button';
import { lazyComponent } from '@unisporkal/react-lazy-component';
import styles from './LocalizationPicker.module.scss';

const LanguageModal = lazyComponent(() =>
  import('../LanguageModal/LanguageModal')
);
const LocaleModal = lazyComponent(() => import('../LocaleModal/LocaleModal'));
const Flag = lazyComponent((props) =>
  import(`../../Flags/${props.country}?icon`)
);

const LocalizationPicker = ({ country, languageName }) => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [isLocaleModalOpen, setIsLocaleModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(country);
  const showLanguageSelector = country.languages.length > 1;

  const showLanguageModal = (newSelectedCountry) => {
    setSelectedCountry(newSelectedCountry);
    setIsLocaleModalOpen(false);
    setIsLanguageModalOpen(true);
  };
  const closeLanguageModal = () => setIsLanguageModalOpen(false);

  const showLocaleModal = () => {
    setIsLanguageModalOpen(false);
    setIsLocaleModalOpen(true);
  };
  const closeLocaleModal = () => setIsLocaleModalOpen(false);

  const onChangeLanguageButtonClick = () => showLanguageModal(country);

  useEffect(() => setSelectedCountry(country), [country]);

  useEffect(() => {
    const handleLoad = () => {
      if (typeof window.eventBus !== 'undefined') {
        window.eventBus.on('openLanguagePicker', showLocaleModal);
      }
    };

    window.addEventListener('load', handleLoad);
    return () => window.removeEventListener('load', handleLoad);
  }, []);

  return (
    <>
      <div>
        {showLanguageSelector && (
          <Button
            className={styles.languageButton}
            variant={ButtonVariantEnum.HOLLOW}
            data-country={country.code}
            onClick={onChangeLanguageButtonClick}
            fullWidth
          >
            {languageName}
            <span className={styles.languageArrow} />
          </Button>
        )}
        <Button
          className={styles.localeButton}
          variant={ButtonVariantEnum.HOLLOW}
          data-country-code={country.code}
          onClick={showLocaleModal}
          fullWidth
        >
          <Flag
            country={country.code}
            className={styles.currentFlag}
          />
          <span className={styles.countryName}>{country.countryName}</span>
        </Button>
      </div>
      {isLanguageModalOpen && (
        <Suspense>
          <LanguageModal
            open={isLanguageModalOpen}
            country={selectedCountry}
            onClose={closeLanguageModal}
            openLocaleModal={showLocaleModal}
          />
        </Suspense>
      )}
      {isLocaleModalOpen && (
        <Suspense>
          <LocaleModal
            open={isLocaleModalOpen}
            onClose={closeLocaleModal}
            openLanguageModal={showLanguageModal}
          />
        </Suspense>
      )}
    </>
  );
};

LocalizationPicker.propTypes = {
  country: PropTypes.shape({
    code: PropTypes.string,
    countryName: PropTypes.string,
    languages: PropTypes.arrayOf(shape({})),
  }).isRequired,
  languageName: PropTypes.string.isRequired,
};

export default LocalizationPicker;
